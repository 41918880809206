<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      permanent
    >
      <v-list dense>
        <v-list-item link to="/">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to='/status'>
          <v-list-item-action>
            <v-icon>mdi-key-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Status</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to='/audit'>
          <v-list-item-action>
            <v-icon>mdi-file-document</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Audit</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item link to='/admin'>
          <v-list-item-action>
            <v-icon>mdi-contact-mail</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>

    </v-navigation-drawer>

    <v-app-bar
      app
      color="rgb(230,229,229)"
      light
      dense
      clipped-left
    >
      <v-toolbar-title>RTB CA</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{userName}} - {{userEmail}}</v-toolbar-title>
      <v-btn 
      style="margin-right: 10px; margin-left: 10px"
      @click="logout"
      >
        Logout
        <v-icon right dark>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-content>
      <v-alert
        v-if="needsPin"
        type="error"
      >
        <v-row align="center">
          <v-col class="grow">
            The security backend needs to be unsealed to enable certificate signing.
          </v-col>
          <v-col class="shrink">
            <v-btn
              color="secondary"
              @click="unsealSecurityBackend"
            >Unseal</v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <router-view />
    </v-content>

    <v-footer 
      app
      >
      <v-row
        justify="center"
        no-gutters
      >
        <v-col
          class="text-center"
          cols="12"
        >
          {{ new Date().getFullYear() }} — RTB GmbH & Co. KG
        </v-col>
      </v-row>
    </v-footer>

  </v-app>
</template>

<script>

// keycloak recommends to load the js directly from the server instance
const loadKeycloakJS = () => {
  return new Promise((resolve, reject) => {
   let script = document.createElement('script')
   script.onload = () => {
    resolve()
   }
   script.onerror = () => {
     reject()
   }
   script.async = true
   script.src = "https://iam.rtb-dev.de/auth/js/keycloak.js"
   document.head.appendChild(script)
  })
}

var keycloak

export default {
  data () {
    return {
      userName: null,
      userEmail: null,
      needsPin: false
    }
  },
  created: function() {
    console.log("created", this.userEmail)
  },
  mounted: function() {


    // check for idle
    var idleTimer = setTimeout(() => { console.log("idle, todo: logout") }, 10*60*1000);
    for(let evt of ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart']) {
      document.addEventListener(evt, () => {
          console.log("evt!");
          clearTimeout(idleTimer);
          idleTimer = setTimeout(() => { console.log("idle, todo: logout") }, 10*60*1000);
      }, true); 
    }

    loadKeycloakJS()
    .then(() => {
      keycloak = new Keycloak({
        url: 'https://iam.rtb-dev.de/auth',
        realm: 'rtb',
        clientId: 'rtb-device-ca-web'
      })
      window.keycloak = keycloak;

      keycloak.init({
        //onLoad: 'login-required'
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
      })
      .then(authenticated => {
          if(authenticated) {
            this.userName = keycloak.idTokenParsed.name;
            this.userEmail = keycloak.idTokenParsed.email;

            console.log("token:", keycloak.token)
            window.api.defaults.headers.Authorization = "Bearer " + keycloak.token;
          }
          else {
            //alert("not authenticated")
            keycloak.login();
          }
      })
      .catch(err =>  {
          alert('keycloak init error ' + err);
          console.error(err)
      });

      keycloak.onAuthRefreshSuccess = () => {
        console.log("onAuthRefreshSuccess");
      }
      keycloak.onAuthRefreshError = () => {
        console.log("onAuthRefreshError");
      }
      keycloak.onAuthLogout = () => {
        console.log("onAuthLogout");
      }

			keycloak.onTokenExpired = () => {
					console.log('keycloak token expired, timeskew:', keycloak.timeSkew, 'token:', keycloak.tokenParsed, 'updating...');
					keycloak.updateToken(30)
					.then(refreshed => {
						if(refreshed) {
							console.log('successfully got a new token:', keycloak.token);
              window.api.defaults.headers.Authorization = "Bearer " + keycloak.token;
						}
						else {
							console.log('token still valid:', keycloak.token);
						}
					})
					.catch(err => {
						console.log('token refresh error:', err);
						keycloak.clearToken();
            this.logout()
					});
			}
    })
    .catch(err => {
      alert('keycloak load error: ' + err);
    });
      
    this.refreshTokenUnsealStatus()
  },
  methods: {
    logout: function() {
      window.keycloak.logout()
    },
    refreshTokenUnsealStatus: async function() {
    },
    unsealSecurityBackend: async function() {
      if (window.location.protocol != "https:") {
        alert("Unsealing needs a https connection");
        throw "invalid protocol"
      }

      let pin = window.prompt("Enter Security Backend / Security Module PIN. \n\nMake sure you're entering this from a reasonably secure device.")
      if(pin) {
        try {
          await window.api.post("/ca/unseal", {"pin": pin})
        }
        catch(err) {
          alert(err)
        }
        await this.refreshTokenUnsealStatus()
      }
    }
  }
}
</script>

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import router from './router'

const axios = require('axios');
const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // TODO: fix this
  headers: { 'Access-Control-Allow-Origin': '*' }
});
//Vue.protoype.$api = api;
window.api = api;

Vue.prototype.$API_BASE_URL = 'blabla';

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

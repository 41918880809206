<template>
  <v-container fluid class="ml-3">

    <h3 class="mt-5 mb-5">
      Certificate Generator
    </h3>

    <v-row>
      <v-col class="col-md4">
        <v-select
          v-model="selected_ca_subject_cn"
          item-value="subject_cn"
          :items="certs"
          :item-text="(i) => { return `${i.subject_cn} \(${i.san_dns}\)` }"
          :rules="[v => !!v || 'Item is required']"
          label="Signing CA"
          required
          />
      </v-col>
      <v-spacer />
    </v-row>

    <div v-if="selected_cert">

    <v-form
      v-if="selected_cert.config === null || selected_cert.can_sign === false"
      class="mt-5"
      >
      Selected ca can't sign (no config or no key on HSM)
    </v-form>
    <v-form
      v-else-if="selected_cert.config && selected_cert.config.type === 'nordic_fw_sign'"
      class="mt-5"
      >
      TODO: nordic
    </v-form>
    <v-form
      v-else-if="selected_cert.config && selected_cert.config.type === 'timestamp_token'"
      class="mt-5"
      >

      <v-text-field
        v-model="timestamp_token_nonce"
        label="Nonce [optional]"
        placeholder=" "
        style
      />

      TODO: timestamp token<br>
      <v-btn class="mb-2" color="primary" @click="generateTimestampToken()">Generate timestamp token</v-btn>
    </v-form>
    <v-form
      v-else
      ref="form"
      :lazy-validation="lazy"
      class="mt-5"
    >

      <v-row>
        <v-col class="col-md4">
        <v-text-field
          v-model="subject_cn"
          label="Common Name [X.509 Subject CN]"
          required
          placeholder=" "
          :suffix="this.subject_cn_suffix"
          style
        ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>

      <v-row>
        <v-col class="col-md4">
          <v-textarea
            v-model="san_dns"
            name="san_dns"
            class="mt-2 col-md4"
            label="Subject (/device) FQDNs [X509 SAN DNS]"
            hint="Multiple entries allowed (newline separated)"
            :placeholder="this.san_dns_placeholder"
            rows=1
            auto-grow
            />
        </v-col>
        <v-spacer></v-spacer>
      </v-row>

      <v-row v-if="show_ips">
        <v-col class="col-md4">
          <v-textarea
            v-model="san_ips"
            name="san_ips"
            label="IP Addresses [X509 SAN IPs]"
            hint="Multiple entries allowed (newline separated)"
            placeholder="192.168.XXX.YYY"
            rows=1
            auto-grow
            />
        </v-col>
        <v-spacer></v-spacer>
      </v-row>

      <v-row v-if="true">
        <v-col class="col-md4">
          <v-textarea
            v-model="san_emails"
            label="EMail [X509 SAN RFC 822 Name]"
            hint="Multiple entries allowed (newline separated)"
            placeholder="foo@bar.com"
            rows=1
            auto-grow
            />
        </v-col>
        <v-spacer></v-spacer>
      </v-row>

      <v-row v-if="true">
        <v-col class="col-md4">
          <v-text-field
            v-model.number="expiry_days"
            label="Expiry (in days)"
						type="number"
            :placeholder="this.selected_cert.config && this.selected_cert.config.max_days.toString()"
            />
        </v-col>
        <v-spacer></v-spacer>
      </v-row>

      <v-row v-if="true">
        <v-col class="col-md6">
          <v-text-field
            v-model="key_passphrase"
            label="Key/Container Encryption Passphrase (optional)"
            />
        </v-col>
        <v-col>
          <v-btn 
            @click="copyKeyPassphrase"
            class="mt-4" 
            small
            >
          <v-icon small color="">mdi-content-copy</v-icon>
            copy
          </v-btn>
          <v-btn 
            @click="regenKeyPassphrase"
            class="mt-4 ml-2" 
            small>
            <v-icon small color="">mdi-refresh</v-icon>
            regen
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>

        <v-textarea
          v-if="show_roles"
          v-model="roles"
          class="mt-3"
          name="roles"
          label="Roles (newline separated)"
          value="admin"
          rows=3
          />

        <v-checkbox
          v-model="include_chain"
          label="Include CA chain"
          />

        <v-btn 
          class="mb-2" 
          color="primary" 
          :disabled="generate_running"
          @click="generateCert('pkcs12')"
        >
          Download (PKCS#12)
          <v-progress-circular v-if="generate_running" class="ml-3" :size="20" :width="2" indeterminate color="white"></v-progress-circular>
        </v-btn>

        <!-- <v-btn 
          class="mb-2" 
          :disabled="generate_running"
          @click="generateCert('pem')"
          style="margin-left: 10px"
        >
          Download (PEM)
          <v-progress-circular v-if="generate_running" class="ml-3" :size="20" :width="2" indeterminate color="white"></v-progress-circular>
        </v-btn> -->

        <v-divider class="mt-5 mb-5" />
        <h4 class="mt-8 mb-5">CSR Signing</h4>
        <v-form>
          <v-textarea
            v-model="csr_pem"
            class="mt-3"
            name="csr_pem"
            label="CSR PEM"
            rows=5
            :disabled="csr_running"
            style="font-size: 14px"
            />
          <v-btn @click="signCSR()">
            Sign & download (pem)
            <v-progress-circular v-if="csr_running" class="ml-3" :size="20" :width="2" indeterminate color="white"></v-progress-circular>
          </v-btn>
        </v-form>

    </v-form>

    </div>

        <v-divider class="mt-5 mb-5" />
        <h3 class="mt-8">API access</h3>
        <pre class="mt-3 mb-5">{{ curlCommand }}</pre>

  </v-container>
</template>

<script>


export default {
  name: 'Home',
  data () {
    return {
      apiBaseURL: process.env.VUE_APP_API_BASE_URL,
      certs: [],
      ca_sn: "",
      selected_ca_subject_cn: null,
      subject_cn: "",
      roles: "",
      san_dns: "",
      san_ips: "",
      san_emails: "",
      csr_pem: null,
      key_passphrase: null,
      expiry_days: null,
      include_chain: false,
      generate_running: false,
      csr_running: false,
      timestamp_token_nonce: null,
    }
  },
  computed: {
    curlCommand: function() {
      let gen_json = {
        ca_name: this.selected_ca_subject_cn,
        subject_cn: this.subject_cn,
        san_dns: this.san_dns.split("\n"),
      //  roles: this.roles.split("\n"),
        format: "pem",
      }

      let sign_json = {
        ca_subject_cn: this.selected_ca_subject_cn,
        csr: "CSR-PEM",
      }

      // todo: ACCESS_TOKEN=$(curl \
      // > -d "client_id=admin-cli" \
      // > -d "username=admin" \
      // > -d "password=admin" \
      // > -d "grant_type=password" \
      // > "https://localhost:8080/auth/realms/master/protocol/openid-connect/token" | jq -r '.access_token')

      return `# generate cert\n# note: depending on use case, it may be more secure to create privkeys on entity devices and sign by csr!\ncurl -X POST \\\n  -H 'Authorization: Bearer TODO' \\\n  -H 'Content-Type: application/json' \\\n  -d '${JSON.stringify(gen_json)}' \\\n  ${this.apiBaseURL}/ca/generate`
       + `\n\n# sign csr\ncurl -X POST \\\n  -H 'Authorization: Bearer TODO' \\\n  -H 'Content-Type: application/json' \\\n  -d '${JSON.stringify(sign_json)}' \\\n  ${this.apiBaseURL}/ca/sign`
    },
    selected_cert: function() {
      return this.certs.find(e => e.subject_cn === this.selected_ca_subject_cn);
    },
    subject_cn_suffix: function() {
      let cert_info = this.selected_cert;
      let config = this.selected_cert.config;
      if(config.subject_cn_is_dns == true) {
        let san_dns = cert_info.san_dns; 
        if(san_dns && san_dns.length == 1) {
          return san_dns[0].replace('*', '')
        }
      }

      return null
      //return JSON.stringify(cert_info.san_dns);
    },
    san_dns_placeholder: function() {
      let cert_info = this.selected_cert;
      let san_dns = cert_info.san_dns; 
      if(san_dns && san_dns.length == 1) {
        return san_dns[0].replace('*', '')
      }
      else {
        return null
      }
    },
    show_ips: function() {
      return this.selected_cert?.config?.extendedKeyUsage?.includes("serverAuth");
    },
    show_roles: function() {
      return false;
    }
  },
  watch: {
    subject_cn: function(val) {
      console.log("subject_cn changed: ", val);
      if(this.subject_cn_suffix) {
        let san_dns_lines = (this.san_dns || "").split("\n");
        san_dns_lines[0] = val + this.subject_cn_suffix;
        this.san_dns = san_dns_lines.join("\n");
      }
    },
    selected_ca_subject_cn: function() {
      this.san_dns = "";
      this.subject_cn = "";
      this.regenKeyPassphrase();
    },
  },
  methods: {
    generateCert: function(format) {
      var subject_cn = this.subject_cn;
      let ca_cert_info = this.selected_cert;

      // auto postfix with allowed san
      if(ca_cert_info.config && ca_cert_info.config.subject_cn_is_dns == true) {
        let allowed_sans = this.selected_cert.san_dns;
        if(allowed_sans.length == 1) {
          let main_san_suffix = allowed_sans[0].replace(/^\*/, "");
          if(!subject_cn.endsWith(main_san_suffix)) {
            subject_cn = subject_cn.replace(/\.+$/, '') + main_san_suffix;
          }
        }
      }

      var json = {
        ca_name: this.selected_ca_subject_cn,
        subject_cn: subject_cn,
        roles: this.roles.split("\n"),
        expiry_days: this.expiry_days,
        format: format,
        key_password: this.key_passphrase,
        include_chain: this.include_chain
      }

      if(this.san_dns && this.san_dns.length > 0) {
        json.san_dns = this.san_dns.split("\n")
      }
      if(this.san_emails && this.san_emails.length > 0) {
        json.san_emails = this.san_emails.split("\n")
      }
      if(this.san_ips && this.san_ips.length > 0) {
        json.san_ips = this.san_ips.split("\n")
      }

      this.generate_running = true;
      //window.keycloak.updateToken(30)
      //.then(() => {
        window.api.post("/ca/generate", json, {responseType: 'json'})
        .then(response => {
          console.log("res: ", response);
          var url;
          if(response.data.data_b64) {
            url = `data:application/x-pkcs12;base64,${response.data.data_b64}`;
          }
          else if(response.data.data) {
            console.log("data:", response.data.data);
            url = window.URL.createObjectURL(new Blob([response.data.data]));
          }

          // const fileExt = response.data.format == 'pem' ? '.pem' : '.p12';
          const link = document.createElement('a');
          link.href = url;
          //link.setAttribute('download', response.data.serial + fileExt);
          link.setAttribute('download', response.data.filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          console.error(err);
          if(err.response.headers["content-type"] === "application/json") {
            alert(JSON.stringify(err.response.data));
          }
          else {
            alert(JSON.stringify(err.response));
          }
        })
        .finally(() => {
          this.generate_running = false;
        })

      //})
      //.catch(() => {
      //  alert("Failed to refresh access token");
      //});
    },
    signCSR: async function() {
      try {
        this.csr_running = true;
        let json = {
          ca_subject_cn: this.selected_ca_subject_cn,
          csr: this.csr_pem,
        }

        let res = await window.api.post("/ca/sign", json, {responseType: 'blob'});
        console.log("sign res:", res)
        console.log("sign data:", res.data)

        let url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'cert.pem');
        document.body.appendChild(link);
        link.click();
      }
      catch(error) {
        alert(error)
      }
      finally {
        this.csr_running = false;
      }
    },
    generateTimestampToken: async function() {
      try {
        let res = await window.api.post("/ca/gen_timestamp_token", null, {responseType: 'blob'});
        console.log("sign res:", res)
        alert("token res:" + JSON.stringify(res));
      }
      catch(error) {
        alert(error)
      }
    },
    copyKeyPassphrase: async function() {
      if(navigator.clipboard == undefined) {
        alert("Error: Clipboard not available in this browser.")
        return;
      }
      try {
        await navigator.clipboard.writeText(this.key_passphrase);
      }
      catch(error) {
        alert(error)
      }
    },
    regenKeyPassphrase: function() {
			var array = new Uint8Array(50);
			window.crypto.getRandomValues(array);
			array = Array.apply([], array ); /* turn into non-typed array */
			array = array.filter(function(x) {
				/* strip non-printables: if we transform into desirable range we have a propability bias, so I suppose we better skip this character */
				return x > 32 && x < 127;
			});
			this.key_passphrase = String.fromCharCode.apply(String, array);
    },
  },
  mounted: function() {
    window.api.get("/certs")
    .then(res => {
      console.log("/certs res:", res, res.data);
      this.certs = res.data.sort((a,b) => {
          return a.subject_cn.localeCompare(b.subject_cn);
      });
      
      // select signable cert by default
      if(this.selected_ca_subject_cn === null) {
        for(let cert of this.certs) {
          if(cert.can_sign === true) {
            this.selected_ca_subject_cn = cert.subject_cn;
            break;
          }
        }
      }
    })
    .catch(err => {
      console.error(err)
      alert("error fetching certs: " + err)
    });

    setInterval(() => {
        console.log("oidc access token expired: ", window.keycloak.isTokenExpired(0))
    }, 30000);
  }
}
</script>
